import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roundOff' })
export class RoundOffPipe implements PipeTransform {

    transform(value: number, place: number) {
        if (typeof value !== "undefined")
            return (value % 1 != 0) ? parseFloat(value.toFixed(place)) : value;
        else
            return value;
    }
}