import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.scss']
})
export class FormButtonComponent implements OnInit {
  @Input() theme: string;
  @Input() size: string;
  @Input() text: string;
  @Input() color: string;
  @Input() buttonType: string;

  public type: string;
  public template: string;

  constructor() { }

  ngOnInit() {
    this.template = this.theme ? this.theme : 'theme1';
  }
}