<ng-container *ngIf="template == 'theme1'">
    <button class="form-button {{size}} {{color}}" [id]="text" [type]="buttonType" [textContent]="text"></button>
</ng-container>

<ng-container *ngIf="template == 'theme2'">
    <button class="form-button btn-with-right-arrow {{size}}" [id]="text" [type]="buttonType" ng-click="onClick($event);">
        <span [textContent]="text" class="{{color}}"></span>
        <span class="glyphicon glyphicon-menu-right {{color}}"></span>
    </button>
</ng-container>

<ng-container *ngIf="template == 'theme3'">
    <button class="form-button btn-with-right-arrow invert no-outline {{size}}" [id]="text" [type]="buttonType">
        <span [textContent]="text" class="{{color}}"></span>
        <span class="glyphicon glyphicon-menu-right {{color}}"></span>
    </button>
</ng-container>

<ng-container *ngIf="template == 'theme4'">
    <button class="form-button invert {{size}}" [type]="buttonType" [id]="text" [textContent]="text"></button>
</ng-container>

<ng-container *ngIf="template == 'theme5'">
    <button class="form-button theme5 {{size}}" [type]="buttonType" [id]="text" [textContent]="text"></button>
</ng-container>

<ng-container *ngIf="template == 'theme6'">
    <button class="form-button transparent right-long-arrow {{size}}" [id]="text" [type]="buttonType">
        <span [textContent]="text"></span>
        <em class="icon ion-ios-arrow-thin-right"></em>
    </button>
</ng-container>

<ng-container *ngIf="template == 'theme7'">
    <button class="btn-transparent brand-color {{size}}" [id]="text" [type]="buttonType">
        <span [textContent]="text"></span>
    </button>
</ng-container>