import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizednumber', pure: false
})
export class LocalizednumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const selectedCulture = localStorage.getItem('culture');
    const culture = selectedCulture.split('-');
    const formatedDate = new DecimalPipe(culture[1]);
    return formatedDate.transform(value);
  }
}
