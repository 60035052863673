<div>
    <body-text class="font-light super-heading4 block text-center">High Flyers Club Contest</body-text>
    <body-text class="block medium-size margin-top-10 text-center">Whoa! You've already completed <body-text
            class="bold super-heading6">90%</body-text> of your journey! <br> Don't slow down now! You're only
        <body-text class="bold super-heading6">20,000</body-text> sales away from crossing the finish line! </body-text>

    <cs-goal-progress-bar-chart class="block margin-top-20" [progressPercentage]="80"
        trackColor="#fff" progressedTrackColor="linear-gradient(to right, #541aa2 , #fc501d)"
        progressIcon="/assets/images/target_goal_rank_car_icon.png"
        goalIcon="/assets/images/profile_target.png"></cs-goal-progress-bar-chart>
    <body-text class="medium-size block margin-top-20">You are currently 20,000 sales away from your target</body-text>

    <div class='target-text-dashboard block-align-center margin-top-40'>
        <div>
            <body-text class="block medium-size color1">TARGET (units)</body-text>
            <body-text class="block super-heading4 color4">2,00,000</body-text>
        </div>
        <div>
            <body-text class="block medium-size color1">ACHIEVED (units)</body-text>
            <body-text class="block super-heading4 color1"><cs-animated-digit [digit]="20000" [duration]="2000"></cs-animated-digit></body-text>
        </div>
        <div>
            <body-text class="block medium-size color1">REMAINING</body-text>
            <body-text class="block super-heading4 color1">20,000</body-text>
        </div>
        <div>
            <body-text class="block medium-size color1">TIME REMAINING</body-text>
            <body-text class="block medium-size color1">30 Days Left</body-text>
            <body-text class="block medium-size">19 Jul'19 -19 Nov'19</body-text>
        </div>
        <div class="points-with-badge flex flex-align-center">
            <div>
                <img class="badge" src="/assets/images/rewards_badge.png" alt="reward badge">
            </div>
            <div class="padding-left-10">
                <body-text class="block medium-size">REWARDS POINTS</body-text>
                <body-text class="block super-heading4 color1">Destination Bali</body-text>
            </div>
        </div>
    </div>

    <a class="block margin-top-40 text-center link-no-decor" ui-sref="main.promotions.aot">
        <form-button text="Know More" theme="theme3" size="md" button-type="button"></form-button>
    </a>

</div>