import { Component, ElementRef, Input, AfterViewInit, ViewChild, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "cs-animated-digit",
    templateUrl: "./animated-digit.component.html"
})
export class AnimatedDigitComponent implements AfterViewInit, OnChanges {
    @Input() duration: number;
    @Input() digit: number = null;
    @Input() steps: number;
    @ViewChild("animatedDigit") animatedDigit: ElementRef;

    animateCount() {        
        if (!this.duration) {
            this.duration = 1000;
        }

        if (typeof this.digit === "number" && this.digit >= 0) {
            this.counterFunc(this.digit, this.duration, this.animatedDigit);
        }
        else {            
            this.animatedDigit.nativeElement.textContent = 0;
        }
    }

    counterFunc(endValue, durationMs, element) {
        if (!this.steps) {
            this.steps = 12;
        }

        const stepCount = Math.abs(durationMs / this.steps);
        const valueIncrement = (endValue - 0) / stepCount;
        const sinValueIncrement = Math.PI / stepCount;

        let currentValue = 0;
        let currentSinValue = 0;

        function step() {
            currentSinValue += sinValueIncrement;
            currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

            if (element)
                element.nativeElement.textContent = Math.abs(Math.floor(currentValue));

          if (currentSinValue < Math.PI) {
            window.requestAnimationFrame(step);
          }
          else {
            element.nativeElement.textContent = endValue;
          }
        }

        step();
    }

    ngAfterViewInit() {
        if (this.digit) {
            this.animateCount();
        }
        else {            
            this.animatedDigit.nativeElement.textContent = 0;
        }
    }

    ngOnChanges(changes: SimpleChanges) {        
        if (changes["digit"] && changes.digit.previousValue != changes.digit.currentValue) {
            this.animateCount();
        }
    }
}
